import "./modal-form.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import close from "../../../assets/icons/close.svg";
import SimplyBook, { Section } from "../../../components/Simplybook/SimplyBook";

export interface PropsModal extends PropsWithChildren<any> {
  title: string;
  is_active: any;
  simplyBookingId: number;
  section: Section;
}

const ModalBook: React.FC<PropsModal> = (props: PropsModal) => {
  const { title } = props;

  return (
    <div className={`o-contact-modal`}>
      <div className={"card-container"}>
        <div className={"m-header-modal"}>
          <h2 className={"m-card__title"}>{title}</h2>
          <img
            alt={"icono de cerrar"}
            src={close}
            className={"a-close"}
            onClick={() => {
              props.is_active(false);
            }}
          />
        </div>
        <div className={"m-card m-card--book-modal"}>
          <SimplyBook id={props.simplyBookingId} section={props.section} />
        </div>
      </div>
    </div>
  );
};

export default ModalBook;
