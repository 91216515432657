import "./navbar-links.scss";

import { useLocation } from "@reach/router";
import { graphql, Link, useStaticQuery } from "gatsby";
import * as React from "react";
import { PropsWithChildren, useState } from "react";

import SidebarSubmenu from "../sidebarMenu/SidebarSubmenu";
import { Wrapper } from "./styles";

interface Props extends PropsWithChildren<any> {
  desktop?: boolean;
}

const NavbarLinks: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const [viewTechniques, setViewTechniques] = useState(false);
  const [viewTraining, setViewTraining] = useState(false);
  const [viewOtherServices, setViewOtherServices] = useState(false);
  const techniques_render = [];
  const training_render = [];
  const otherService_render = [];
  const data = useStaticQuery(graphql`
    query menuQuery {
      allSanityTechnique(
        filter: {
          _id: { ne: "7c9ab6f4-3da6-45bd-964c-ae2fc3a775e1" }
          has_inMenu: { eq: true }
        }
        sort: { fields: position, order: ASC }
      ) {
        nodes {
          slug {
            current
          }
          card {
            title
          }
        }
      }
      allSanityTraining(
        filter: { has_inMenu: { eq: true } }
        sort: { fields: position, order: ASC }
      ) {
        nodes {
          slug {
            current
          }
          card {
            title
          }
        }
      }
      allSanityOtherServices(
        filter: { has_inMenu: { eq: true } }
        sort: { fields: position, order: ASC }
      ) {
        nodes {
          slug {
            current
          }
          card {
            title
          }
        }
      }
    }
  `);

  //<editor-fold desc="dotCircle">
  const dotCircle = (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="dot-circle"
      role="img"
      width={"14"}
      height={"14"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={"dot-circle"}
    >
      <path d="M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z"></path>
    </svg>
  );
  //</editor-fold>

  //<editor-fold desc="arrowRight">
  const arrowRight = (
    <svg
      width={"14"}
      height={"14"}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="chevron-circle-right"
      className={"dot-circle"}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z"></path>
    </svg>
  );
  //</editor-fold>

  //<editor-fold desc="dotPlus">
  const dotPlus = (
    <svg
      width={"14"}
      height={"14"}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="plus-circle"
      className={"dot-circle"}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"
      ></path>
    </svg>
  );
  //</editor-fold>

  //<editor-fold desc="arrowDown">
  const arrowDown = (
    <svg
      width={"14"}
      height={"14"}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="arrow-circle-down"
      className={"dot-circle"}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-143.6-28.9L288 302.6V120c0-13.3-10.7-24-24-24h-16c-13.3 0-24 10.7-24 24v182.6l-72.4-75.5c-9.3-9.7-24.8-9.9-34.3-.4l-10.9 11c-9.4 9.4-9.4 24.6 0 33.9L239 404.3c9.4 9.4 24.6 9.4 33.9 0l132.7-132.7c9.4-9.4 9.4-24.6 0-33.9l-10.9-11c-9.5-9.5-25-9.3-34.3.4z"
      ></path>
    </svg>
  );
  //</editor-fold>

  //<editor-fold desc="TechniquesMenu">
  techniques_render.push(
    <Link
      to={`/`}
      key={"home"}
      className={`a-menu-link a-menu-link--sub  ${
        location.pathname === `/` ? "a-menu-link--active" : ""
      } `}
    >
      {dotCircle}
      <span className={"a-menu__text"}>Sesión fisioterapía</span>
    </Link>
  );
  data.allSanityTechnique.nodes.forEach((technique, index) =>
    techniques_render.push(
      <Link
        key={index}
        to={`/${technique.slug.current}/`}
        className={`a-menu-link a-menu-link--sub  ${
          location.pathname === `/${technique.slug.current}/`
            ? "a-menu-link--active"
            : ""
        } `}
      >
        {dotCircle}
        <span className={"a-menu__text"}>{technique.card.title}</span>
      </Link>
    )
  );
  //</editor-fold>

  //<editor-fold desc="TrainingMenu">
  data.allSanityTraining.nodes.forEach((training, index) =>
    training_render.push(
      <Link
        key={index}
        to={`/${training.slug.current}/`}
        className={`a-menu-link a-menu-link--sub  ${
          location.pathname === `/${training.slug.current}/`
            ? "a-menu-link--active"
            : ""
        } `}
      >
        {dotCircle}
        <span className={"a-menu__text"}>{training.card.title}</span>
      </Link>
    )
  );
  //</editor-fold>

  data.allSanityOtherServices.nodes.forEach((otherService, index) =>
    otherService_render.push(
      <Link
        key={index}
        to={`/${otherService.slug.current}/`}
        className={`a-menu-link a-menu-link--sub  ${
          location.pathname === `/${otherService.slug.current}/`
            ? "a-menu-link--active"
            : ""
        } `}
      >
        {dotCircle}
        <span className={"a-menu__text"}>{otherService.card.title}</span>
      </Link>
    )
  );

  function toggleMenu(e, slug) {
    if (slug === "techniques") {
      e.stopPropagation();
      e.preventDefault();
      setViewTechniques(!viewTechniques);
      setViewTraining(false);
      setViewOtherServices(false);
    }
    if (slug === "trainings") {
      e.stopPropagation();
      e.preventDefault();
      setViewTraining(!viewTraining);
      setViewTechniques(false);
      setViewOtherServices(false);
    }
    if (slug === "otherServices") {
      e.stopPropagation();
      e.preventDefault();
      setViewTraining(false);
      setViewTechniques(false);
      setViewOtherServices(!viewOtherServices);
    }
  }

  return (
    <>
      <Wrapper
        desktop={props.desktop}
        onClick={(e) => {
          toggleMenu(e, "close");
        }}
      >
        <span
          onClick={(e) => {
            toggleMenu(e, "techniques");
          }}
          className={`a-menu-link`}
        >
          {viewTechniques && <span className={"a-menu-line"}></span>}
          {dotPlus}
          <span className={"a-menu__text"}>Fisioterapia</span>
        </span>
        <div
          className={
            viewTechniques
              ? "sidebar__hidden o-sidebar-submenu__wrapper"
              : "sidebar__hidden"
          }
        >
          <SidebarSubmenu>{techniques_render}</SidebarSubmenu>
        </div>
        <span
          className={"a-menu-link"}
          onClick={(e) => {
            toggleMenu(e, "trainings");
          }}
        >
          {viewTraining && <span className={"a-menu-line"}></span>}
          {dotPlus}
          <span className={"a-menu__text"}>Trainings</span>
        </span>
        <div
          className={
            viewTraining ? "o-sidebar-submenu__wrapper" : "sidebar__hidden"
          }
        >
          <SidebarSubmenu>{training_render}</SidebarSubmenu>
        </div>

        <span
          className={"a-menu-link"}
          onClick={(e) => {
            toggleMenu(e, "otherServices");
          }}
        >
          {viewOtherServices && <span className={"a-menu-line"}></span>}
          {dotPlus}
          <span className={"a-menu__text"}>Otros servicios</span>
        </span>
        <div
          className={
            viewOtherServices ? "o-sidebar-submenu__wrapper" : "sidebar__hidden"
          }
        >
          <SidebarSubmenu>{otherService_render}</SidebarSubmenu>
        </div>

        <Link
          to="/tarifas/"
          className={`a-menu-link ${
            location.pathname === "/tarifas/" ? "a-menu-link--active" : ""
          } `}
        >
          {dotCircle}
          <span className={"a-menu__text"}>Tarifas</span>
        </Link>
        {!props.desktop && (
          <a
            href={
              "https://www.treebole-clinicas.net/es/customer/requestappo/cusrc/g84fzxc8vvaguap"
            }
            target="_blank"
            rel="noopener noreferrer nofollow"
            className={"a-menu-link a-menu-link--cta"}
          >
            {arrowRight}
            <span className={"a-menu__text"}>Pide cita</span>
          </a>
        )}
      </Wrapper>
    </>
  );
};

export default NavbarLinks;
