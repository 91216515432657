import "./navbar.scss";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "assets/images/logo-white.png";
import { Link } from "gatsby";
import { useState } from "react";
import * as React from "react";

import ModalBook from "../../../modalForm/ModalBook";
import NavbarLinks from "../navbarLinks";

const Navbar = () => {
  const [has_modal, setHasModal] = useState(false);

  return (
    <>
      {has_modal && (
        <ModalBook
          title="Pide cita ahora"
          is_active={setHasModal}
          simplyBookingId={0}
          section={"OTHERS"}
        />
      )}
      <div className="m-header container wrapper grid-x3">
        <Link to="/">
          <img src={logo} alt="Obelion Fisioterapia" width="200" height="35" />
        </Link>
        <NavbarLinks desktop />
        <button className={"a-button"} onClick={() => setHasModal(true)}>
          Pide cita
        </button>
        {/*<a*/}
        {/*  href={*/}
        {/*    "https://www.treebole-clinicas.net/es/customer/requestappo/cusrc/g84fzxc8vvaguap"*/}
        {/*  }*/}
        {/*  target={"_blank"}*/}
        {/*  rel={"noopener noreferrer nofollow"}*/}
        {/*  className={"a-button"}*/}
        {/*>*/}
        {/*  Pide cita*/}
        {/*</a>*/}
      </div>
    </>
  );
};

export default Navbar;
