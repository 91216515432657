import "./simply-book.scss";

import * as React from "react";
import { PropsWithChildren } from "react";
import { Helmet } from "react-helmet";

import DangerouslySetHtmlContent from "../DangerouslySetInnerHTML";

export type Section = "TECHNIQUE" | "TRAINING" | "OTHERS";
interface PropsSimplyBook extends PropsWithChildren<any> {
  id: number;
  section: Section;
}

const SimplyBook: React.FC<PropsSimplyBook> = (props: PropsSimplyBook) => {
  const { id } = props;
  let predefined = `[]`;
  if (id !== 0) {
    predefined = `{"location":"1","service":"${id}"}`;
  }
  if (id === 0 && props.section === "TECHNIQUE") {
    predefined = `{"location":"1","category":"1"}`;
  }
  if (id === 0 && props.section === "TRAINING") {
    predefined = `{"location":"1","category":"2"}`;
  }
  console.log("this is id", id);
  console.log("this is section", props.section);
  const html = `<script>
                var widget = new SimplybookWidgetRW({"widget_type":"iframe","url":"https://obelionhldg.simplybook.it","theme":"minimal","theme_settings":{"timeline_show_end_time":"1","timeline_modern_display":"as_slots","hide_company_label":"0","timeline_hide_unavailable":"1","hide_past_days":"0","sb_base_color":"#A3BCC4","btn_color_1":"#A3BCC4,#A3BCC4,#A3BCC4","link_color":"#A3BCC4","display_item_mode":"block","body_bg_color":"#FFFFFF","sb_review_image":"37","sb_review_image_preview":"\\\\/uploads\\\\/obelionhldg\\\\/image_files\\\\/preview\\\\/1131320856a599a63591086b3246d573.png","dark_font_color":"#343434","light_font_color":"#FFFFFF","sb_company_label_color":"#A3BCC4","hide_img_mode":"0","sb_busy":"#AAA6AA","sb_available":"#343434"},"timeline":"modern","datepicker":"top_calendar","is_rtl":false,"app_config":{"clear_session":0,"allow_switch_to_ada":0,"predefined":${predefined}}});
              </script>`;
  return (
    <>
      <div id="widget"></div>
      <DangerouslySetHtmlContent html={html} />
    </>
  );
};

export default SimplyBook;
